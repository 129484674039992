<template>
  <div class="relative px-16px grid grid-cols-10 gap-16px information-tab">
    <div v-if="isLoading" class="spin-custom">
      <cds-loading />
    </div>
    <div class="col-span-7 mt-16px pb-16px">
      <div class="bg-ems-gray800 pt-18px px-20px pb-28px">
        <div class="text-ems-gray200 text-16px mb-20px font-semibold uppercase">
          {{ t('object_tracing.ident_info') }}
        </div>
        <div class="grid grid-cols-3 gap-30px">
          <div>
            <a-form-item
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :label="t('object_tracing.object_type')"
              :name="['type']"
            >
              <cds-select
                v-model:value="tracingObjectDetail.type"
                :options="objectTypeList"
                :placeholder="t('object_tracing.object_type_placeholder')"
                disabled
              />
            </a-form-item>
          </div>
          <div>
            <a-form-item
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :label="t('object_tracing.object_name')"
              :name="['name']"
            >
              <cds-input
                v-model:value="tracingObjectDetail.name"
                :maxlength="100"
                :show-count="false"
                :placeholder="''"
                disabled
              />
            </a-form-item>
          </div>
          <div :class="tracingObjectDetail.status ? 'input-text-red' : ''">
            <a-form-item
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :label="t('object_tracing.status')"
              :name="['status']"
            >
              <cds-input
                :value="statusText"
                :maxlength="255"
                :show-count="false"
                :placeholder="t('object_tracing.characteristic_placeholder')"
                disabled
              />
            </a-form-item>
          </div>
        </div>
        <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
        <div class="text-ems-gray200 text-16px mb-20px font-semibold mt-14px">
          {{ t('object_tracing.group_belong') }}
        </div>
        <div class="flex flex-wrap gap-16px">
          <div v-if="listGroupDetail.length < 1" class="text-ems-main2">
            {{ t('object_tracing.not_belong_any_group') }}
          </div>
          <div
            v-else
            v-for="item in listGroupDetail"
            :key="item.id"
            class="group tag bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative cursor-pointer"
            :class="{
              'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
              'bg-ems-boTro2_600': item.warningLevel === WARNING_LEVEL[1].id,
              'bg-ems-boTro4_800': item.warningLevel === WARNING_LEVEL[2].id,
              'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
            }"
            :title="item.name"
            @click="viewGroup(item)"
          >
            {{
              item.name > 20 ? `${item.name.substring(0, 20)}...` : item.name
            }}
          </div>
        </div>
      </div>
      <div class="mt-16px gap-16px">
        <div class="relative bg-ems-gray800 min-h-615px p-20px">
          <div class="flex justify-between border-b border-ems-gray700">
            <div>
              <div class="text-ems-white text-16px font-semibold leading-25px">
                {{ t('object_tracing.statistics_of_the_frequency') }}
              </div>
              <div class="text-ems-gray400 text-14px mt-13px">
                {{ t('object_tracing.the_total_appears') }}
              </div>
              <div class="text-ems-main2 text-28px font-semibold leading-44px">
                {{
                  tracingObjectDetail.frequency
                    ? tracingObjectDetail.frequency.total
                    : 0
                }}
              </div>
            </div>
            <div class="chart-prefix-datepicker">
              <div class="flex gap-20px items-center">
                <div class="flex">
                  <div
                    class="flex items-center text-ems-gray50 text-14px bg-ems-gray700 h-39px rounded-l-4px pl-9px"
                  >
                    {{ t('common.from-date') }}:
                  </div>
                  <cds-date-picker
                    v-model:value="dateFrom"
                    placeholder="DD/MM/YYYY"
                    :disabled-date="disabledDateFrom"
                    :is-rounded="true"
                  />
                </div>
                <div class="flex">
                  <div
                    class="flex items-center text-ems-gray50 text-14px bg-ems-gray700 h-39px rounded-l-4px pl-9px"
                  >
                    {{ t('common.to-date') }}:
                  </div>
                  <cds-date-picker
                    v-model:value="dateTo"
                    placeholder="DD/MM/YYYY"
                    :disabled-date="disabledDateTo"
                    :is-rounded="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <Chart
            ref="frequencyChart"
            :option="chartData"
            class-name="chart bg-evisa-gray50 rounded-lg pr-4 min-h-[500px]"
            width="100%"
            :height="heightTb"
          ></Chart>
        </div>
      </div>
    </div>
    <div class="col-span-3" v-if="!isLoading">
      <div class="bg-ems-gray800 w-full mt-16px pt-18px pb-34px px-20px">
        <div class="text-ems-gray200 text-16px mb-20px font-semibold uppercase">
          {{ t('object_tracing.image') }}
        </div>
        <div class="w-full relative">
          <div class="w-full h-237px relative">
            <div
              class="w-full h-full relative group image-tracing-custom"
              v-if="tracingObjectDetail.images && tracingObjectDetail.images[0]"
            >
              <Image
                :src="tracingObjectDetail.images[0]"
                :alt="tracingObjectDetail.images[0]"
                class="block w-full h-full rounded-8px"
              >
                <template #previewMask>
                  <cds-feather-icons
                    class="cursor-pointer"
                    color="#FFFFFF"
                    type="eye"
                    size="16"
                  />
                </template>
              </Image>
            </div>
            <div
              v-else
              class="w-full h-full bg-ems-gray700 rounded-8px flex flex-col justify-center"
            >
              <img
                src="../../../../static/img/icon/ImageIcon.svg"
                alt="Icon Upload"
                class="block mx-auto"
                loading="lazy"
              />
              <div class="text-center text-ems-gray300">
                {{ t('object_information.no_image') }}
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-2 2xl:grid-cols-3 gap-[14px] max-h-400px overflow-auto mt-20px"
          >
            <Image.PreviewGroup>
              <div
                v-for="(item, index) in tracingObjectDetail.images"
                class="group relative h-104px rounded-8px overflow-hidden cursor-pointer image-tracing-custom"
                :class="index === 0 ? 'hidden' : 'block'"
              >
                <Image
                  v-if="item"
                  class="w-full h-ful block object-cover"
                  :alt="item"
                  :src="item"
                >
                  <template #previewMask>
                    <cds-feather-icons
                      class="cursor-pointer"
                      color="#FFFFFF"
                      type="eye"
                      size="16"
                    />
                  </template>
                </Image>
                <div v-else class="w-full h-full">
                  <a-spin />
                </div>
              </div>
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    :visible="previewVisible"
    :title="tracingObjectDetail.id"
    :footer="null"
    @cancel="handleCancel"
  >
    <img
      :alt="tracingObjectDetail.name"
      style="width: 100%"
      :src="previewUrl"
      loading="lazy"
    />
  </a-modal>
</template>
<script setup>
import { WARNING_LEVEL, RELATIONSHIP } from '../../../../config/Constant';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { OBJECT_TYPE } from '../../../../util/common-constant';
import Chart from './Chart.vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { Image } from 'ant-design-vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const tracingObjectDetail = computed(
  () => state.tracingObject.tracingObjectDetail
);
const dataChart = computed(() => state.tracingObject.dataChart);
const isLoading = computed(() => state.tracingObject.isLoading);
const listGroup = computed(() => state.ioGroup.listGroup);
const listGroupDetail = computed(() => {
  let list = [];
  if (tracingObjectDetail.value.ioGroups && listGroup.value) {
    tracingObjectDetail.value.ioGroups.forEach((item) => {
      const index = listGroup.value.findIndex((group) => group.id === item);
      if (index !== -1) {
        list.push(listGroup.value[index]);
      }
    });
  }
  return list;
});
const previewVisible = ref(false);
const previewUrl = ref('');
const onShowPreviewImg = (url) => {
  previewVisible.value = true;
  previewUrl.value = url;
};
const handleCancel = () => {
  previewVisible.value = false;
  previewUrl.value = '';
};
const statusText = computed(() =>
  tracingObjectDetail.value && tracingObjectDetail.value.status
    ? t('object_tracing.tracing')
    : t('object_tracing.pause')
);
const chartData = reactive({
  tooltip: {
    backgroundColor: '#373737',
    formatter: function (params) {
      if (params.data.id) {
        const obj = tracingObjectDetail.value.frequency.statistic.find(
          (item) => item.profileUUID === params.data.id
        );
        const group = listGroup.value.find(
          (item) => item.id === obj.ioGroups[0]
        );

        const getRelationship = () => {
          let arr = [];
          for (let i in obj.relate) {
            if (obj.relate[i]) {
              arr.push(t(RELATIONSHIP[i]));
            }
          }
          return arr.length > 0 ? arr.join(' ,') : '';
        };
        return `<div class="flex gap-20px">
              <img src="${
                obj.imagesAvatar
                  ? obj.imagesAvatar
                  : require('@/static/img/no-img.png')
              }" class="rounded-8px w-[120px] h-[120px] flex-shrink-0 object-cover">
              <div class="flex flex-col gap-5px">
                <p class="text-18px font-semibold text-ems-gray100">${
                  params.data.name
                }</p>
                <div class="flex">
                    <span class="text-ems-gray300">${t(
                      'object_tracing.group'
                    )}:&nbsp;</span>
                  <span class="rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray200
                  ${group ? '' : 'hidden'}
                  ${
                    group
                      ? WARNING_LEVEL[group.warningLevel - 1].color
                      : 'bg-ems-gray500'
                  }">
                  ${group && group.name}
                  </span>
                  <span class="rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray200 bg-ems-gray500 ${
                    obj.ioGroups && obj.ioGroups.length > 1 ? '' : 'hidden'
                  }">
                  ...
                  </span>
                  <span class="text-ems-gray300 ${group ? 'hidden' : ''}">${t(
          'object_tracing.none'
        )}</span>
                </div>
                <span class="text-ems-gray300">${t(
                  'object_tracing.relationship2'
                )} ${
          getRelationship()
            ? `<span class="text-ems-main2 font-bold">${getRelationship()}</span>`
            : t('object_tracing.relationship-unknown')
        }</span>
                <span class="text-ems-gray300">${t(
                  'object_tracing.frequency'
                )} <span class="text-ems-main2 font-bold">${obj.count} ${t(
          'object_tracing.times'
        )}</span></span>
              </div>
              </div>`;
      }
      return '';
    },
  },
  series: [
    {
      name: t('object_tracing.frequency-appear'),
      type: 'treemap',
      data: [
        {
          value: 0,
          name: t('object_tracing.frequency-appear'),
          children: [],
        },
      ],
      visibleMin: 300,
      leafDepth: 2,
      breadcrumb: {
        show: false,
      },
      levels: [
        {
          itemStyle: {
            color: '#B5122E',
            borderColor: '#555',
            borderWidth: 4,
            gapWidth: 4,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
          itemStyle: {
            color: '#B5122E',
            borderColorSaturation: 0.7,
            gapWidth: 2,
            borderWidth: 2,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
          itemStyle: {
            color: '#B5122E',
            borderColorSaturation: 0.6,
            gapWidth: 1,
          },
        },
        {
          colorSaturation: [0.8, 0.3],
        },
      ],
    },
  ],
});
const heightTb = ref('calc(100vh - 700px)');
const objectTypeList = [
  { value: OBJECT_TYPE.HUMAN, label: t('object_information.human') },
  { value: OBJECT_TYPE.VEHICLE, label: t('object_information.vehicle') },
  { value: OBJECT_TYPE.LOCATION, label: t('object_information.location') },
  { value: OBJECT_TYPE.SYMBOL, label: t('object_information.symbol') },
];
const frequencyChart = ref(null);
watch(
  () => [dataChart.value, frequencyChart.value],
  () => {
    chartData.series[0].data[0].value = tracingObjectDetail.value.frequency
      ? tracingObjectDetail.value.frequency.total
      : 0;
    chartData.series[0].data[0].children = dataChart.value.map((item) => ({
      name: item.name,
      value: item.value,
      id: item.id,
    }));
  }
);
const dateFrom = ref(dayjs().subtract(30, 'day'));
const dateTo = ref(dayjs());
watch(
  () => [dateFrom.value, dateTo.value],
  () => {
    onChangeFilterDate();
  }
);
const onChangeFilterDate = () => {
  dispatch('tracingObject/getDetail', {
    id: route.query.id,
    params: {
      dateFrom: dateFrom.value
        ? dayjs(dateFrom.value).startOf('day').toDate()
        : null,
      dateTo: dateTo.value ? dayjs(dateTo.value).endOf('day').toDate() : null,
    },
  });
};
const viewGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item.id },
  });
};
const disabledDateFrom = (current) => {
  return current && current > dayjs(dateTo.value).endOf('day');
};
const disabledDateTo = (current) => {
  return current && current < dayjs(dateFrom.value).startOf('day');
};
onMounted(() => {
  dispatch('tracingObject/getDetail', {
    id: route.query.id,
    params: {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
    },
  }),
  dispatch('ioGroup/getAllGroup');
});
</script>
<style lang="scss">
.information-tab .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.information-tab .input-text-red .ant-input-sm .ant-input {
  --tw-text-opacity: 1;
  color: rgba(226, 26, 61, var(--tw-text-opacity));
}
.image-tracing-custom .ant-image {
  height: 100%;
  width: 100%;
}
.image-tracing-custom .ant-image-img {
  height: 100% !important;
}
img.ant-image-preview-img {
  display: inline-block;
}
.chart-prefix-datepicker .ant-picker {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
